import React from "react";
import {
  As,
  Box,
  Container,
  Flex,
  Icon,
  Img,
  Link,
  Show,
  Spacer,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IconButton } from "../../../core/components/Button/IconButton";
import { MenuScale } from "iconoir-react";
import { MobileDrawer } from "./Drawer/MobileDrawer";
import { LanguageSwitch } from "./LanguageSwitch/LanguageSwitch";

type NavItem = {
  href: string;
  label: string;
  icon?: As;
};

interface NavbarProps {
  navItems: NavItem[];
  rightChildren?: React.ReactNode;
}
const PATH = "/assets/images/";

export const Navbar: React.FC<NavbarProps> = ({ navItems, rightChildren }) => {
  const { t } = useTranslation("web");
  const logoSrc = useColorModeValue(`${PATH}logo.png`, `${PATH}logo-white.png`);
  const disclosureProps = useDisclosure();

  return (
    <Flex
      as="nav"
      padding="1.5rem"
      bg="transparent"
      position="fixed"
      width="100%"
      zIndex="100"
      backgroundColor="background"
    >
      <Container maxWidth="container.xl" display="flex" alignItems="center">
        <Box>
          <Img src={logoSrc} alt="Logo" width="100px" />
        </Box>
        <Spacer />

        <Show above="md">
          <Box
            display="flex"
            width="auto"
            alignItems="center"
            flexGrow={2}
            gap={10}
            maxWidth="500px"
          >
            {navItems.map(({ href, label, icon }) => (
              <Link key={href} href={href} display="inline-flex" alignItems="center">
                {icon && <Icon as={icon} color="primaryFontColor" mr={2} />}
                {t(label)}
              </Link>
            ))}
          </Box>
        </Show>
        <Spacer />
        <Flex gap={8}>
          <LanguageSwitch />

          <Show above="md">{rightChildren}</Show>
          <Show below="lg">
            <IconButton
              aria-label={t("open-menu")}
              icon={<MenuScale />}
              onClick={disclosureProps.onToggle}
            />
          </Show>

          <MobileDrawer
            disclosureProps={disclosureProps}
            items={navItems}
            rightChildren={rightChildren}
          />
        </Flex>
      </Container>
    </Flex>
  );
};
