import React from "react";
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from "@chakra-ui/react";
import { HEIGHT_MD, HEIGHT_SM } from "../../theme/components/button";

export interface IconButtonProps extends ChakraIconButtonProps {}

const sizeMap: Map<string, string> = new Map([
  ["sm", HEIGHT_SM],
  ["md", HEIGHT_MD],
]);

export const IconButton: React.FC<IconButtonProps> = ({ size, ...rest }) => {
  // @ts-ignore
  return (
    <ChakraIconButton
      size={size}
      width={sizeMap.get((size as string) || "md")}
      minWidth={0}
      display="inline-flex"
      {...rest}
    />
  );
};
