import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Show,
} from "@chakra-ui/react";
import { NavItems } from "../data";
import { useTranslation } from "next-i18next";
import { UseDisclosureReturn } from "@chakra-ui/hooks/dist/use-disclosure";
import { AppButton } from "../AppButton";
import React from "react";

interface DrawerProps {
  items: NavItems;
  disclosureProps: UseDisclosureReturn;
  rightChildren?: React.ReactNode;
}

export const MobileDrawer: React.FC<DrawerProps> = ({ items, disclosureProps, rightChildren }) => {
  const { isOpen, onOpen, onClose } = disclosureProps;

  const { t } = useTranslation("web");

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody py={10}>
              <Flex flexDirection="column" justifyContent="space-between" height="100%">
                <Flex flexDirection="column" gap={8}>
                  {items.map((item) => (
                    <Link
                      key={item.href}
                      href={item.href}
                      onClick={onClose}
                      fontSize="29px"
                      textTransform="uppercase"
                    >
                      {t(item.label)}
                    </Link>
                  ))}
                </Flex>

                {rightChildren}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
