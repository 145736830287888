import Head from "next/head";
import { BlitzLayout } from "@blitzjs/next";
import {
  ChakraProvider,
  ColorModeScript,
  CSSReset,
  GlobalStyle,
  LightMode,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import Script from "next/script";
import React from "react";
import { Playfair_Display } from "next/font/google";
import { Navbar } from "../../web/components/Navbar/Navbar";
import { navItems } from "../../web/components/Navbar/data";
import { AppButton } from "../../web/components/Navbar/AppButton";
import { Footer } from "../../web/components/Footer/Footer";

const lightTheme = {
  ...theme,
  styles: {
    globals: {},
  },
  config: {
    ...theme.config,
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};

const playfairDisplay = Playfair_Display({ subsets: ["latin-ext"], display: "swap" });

const Layout: BlitzLayout<{ title?: string }> = ({ title, children }) => {
  return (
    <>
      <style jsx global>{`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${playfairDisplay.style.fontFamily} !important;
        }
      `}</style>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-8CPRRVRBVQ"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-8CPRRVRBVQ');
        `}
      </Script>

      <Head>
        <title>{title || "aprety"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <LightMode>
        <GlobalStyle />
        <ColorModeScript initialColorMode={"light"} />

        <Navbar
          navItems={navItems}
          rightChildren={<AppButton buttonProps={{ width: { base: "100%", md: "auto" } }} />}
        />
        {children}
        <Footer />
      </LightMode>
    </>
  );
};

export default Layout;
