import React from "react";
import { TextProps, Text } from "@chakra-ui/react";

interface Props extends TextProps {
  children?: React.ReactNode;
}

export const Heading: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <Text as="h2" {...restProps}>
      {children}
    </Text>
  );
};

export const SectionHeading: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <Text as="h3" fontSize={{ base: 60, lg: 112 }} textTransform="capitalize" {...restProps}>
      {children}
    </Text>
  );
};
