import React, { useRef } from "react";
import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import rocket from "./rocket.png";
import circles from "./circles.png";
// import Circles from "./circles.svg";
import { SectionHeading } from "../Heading";

interface Props {}

export const MISSION_ID = "mission";

export const Mission: React.FC<Props> = () => {
  const { t } = useTranslation("web");
  const section = useRef(null);

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      gsap
        .timeline({
          scrollTrigger: {
            trigger: section.current,
            start: "10% center",
            end: "40% 20%",
            scrub: 1,
            markers: false,
          },
        })
        .from(".headingContainer", {
          y: "70%",
          ease: "none",
        })
        .to(".rocket", {
          y: "-100vh",
        });

      gsap.to(".circles", {
        scrollTrigger: {
          trigger: section.current,
          start: "bottom 80%",
          end: "+=100%",
          scrub: 0,
          markers: false,
        },
        bottom: "0",
      });
    },
    { scope: section },
  );

  return (
    <Flex minHeight={"70vh"} alignItems="center" ref={section} id={MISSION_ID} position="relative">
      <Container maxWidth="container.xl" p={14}>
        <Flex
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="headingContainer"
        >
          <Image src={rocket.src} alt="rocket_image" width={130} height={130} className="rocket" />
          <SectionHeading mt={-10}>{t("mission")}</SectionHeading>
        </Flex>

        <Text fontSize={{ base: 18, lg: 24 }} fontStyle="italic" maxWidth="container.lg">
          {t("mission_text")}
        </Text>
      </Container>

      <Box
        position="absolute"
        right={{ base: 10, lg: "10vw" }}
        bottom={"-200px"}
        className="circles"
      >
        <Image src={circles.src} alt={"circles"} width={120} height={120} />
      </Box>
    </Flex>
  );
};
