import React, { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { Box, Container, Flex, Text } from "@chakra-ui/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "next-i18next";

import { Heading } from "../Heading";
import Line from "./line.svg";

interface Props {}

const PeopleHeading = ({ children }) => (
  <Heading as="h3" fontSize={{ base: 48, lg: 128 }}>
    {children}
  </Heading>
);

const sections = [
  { title: "journalists_title", description: "journalists_description" },
  { title: "brands_title", description: "brands_description" },
  { title: "individuals_title", description: "individuals_description" },
  { title: "more_title", description: "more_description" },
];

export const PEOPLE_ID = "people";

export const People: React.FC<Props> = () => {
  const section = useRef(null);
  const container = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("web");

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      const getScrollAmount = () => {
        const containerWidth = container?.current?.offsetWidth;
        if (!containerWidth) return 0;

        const ammountToScroll = containerWidth - window.innerWidth;

        return ammountToScroll;
      };
      // sections
      const tween = gsap.to(container.current, {
        x: -getScrollAmount(),
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          start: "top",
          end: () => `+=${getScrollAmount()}`,
          scrub: 3,
          pin: true,
          markers: false,
          invalidateOnRefresh: true,
        },
      });

      // draw the line
      const drawLine = gsap.to(".lineWrapper", {
        width: "100%",
        scrollTrigger: {
          trigger: section.current,
          start: "top 10%",
          end: () => `+=${getScrollAmount()}`,
          scrub: 3,

          markers: false,
          invalidateOnRefresh: true,
        },
      });
    },
    { scope: section },
  );

  return (
    <Box ref={section} id={PEOPLE_ID} overflow="hidden">
      <Box position={"fixed"} top={"50%"} className="lineWrapper" width={0} overflow="hidden">
        <Line />
      </Box>
      <Flex ref={container} width={"fit-content"} flexWrap="nowrap">
        {sections.map((section) => (
          <Flex
            flexDirection="column"
            gap={4}
            width="100vw"
            justifyContent="center"
            alignItems="center"
            key={section.title}
            height="100vh"
          >
            <Flex height="50%" alignItems="flex-end">
              <PeopleHeading>{t(section.title)}</PeopleHeading>
            </Flex>
            <Box height="50%" pt={10}>
              <Container maxWidth="container.lg">
                <Text fontSize={{ base: 20, lg: 32 }}>{t(section.description)}</Text>
              </Container>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
