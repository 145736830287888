import React, { useRef } from "react";
import { Box, Container, Flex, SimpleGrid, Text, useToken } from "@chakra-ui/react";
import { SectionHeading } from "../Heading";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "next-i18next";
import { faker } from "@faker-js/faker";
import { ContactUsButton } from "./ContactUsButton";

interface Props {}

export const CONTACT_US_ID = "contact-us";

export const ContactUs: React.FC<Props> = () => {
  const section = useRef(null);
  const { t } = useTranslation("web");
  const whiteBg = useToken("colors", "gray.50");

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger, TextPlugin);

      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".items",
            start: "top 70%",
            end: "+=20vh",
            scrub: 3,
            markers: false,
            invalidateOnRefresh: true,
          },
        })
        .to(".heading", {
          duration: 10,
          text: {
            value: t("contact_us_title"),
          },
        })
        .from(".button", {
          opacity: 0,
          scale: 0.9,
        })
        .to(".button", {
          scale: 1.3,
          duration: 0.5,
          opacity: 1,
        });

      gsap.to(".typings", {
        scrollTrigger: {
          trigger: section.current,
          start: "top 80%",
          end: "+=20%",
          markers: false,
          invalidateOnRefresh: true,
        },
        duration: 100,
        text: {
          value: faker.lorem.words(50),
        },
      });
    },
    { scope: section },
  );

  return (
    <Flex
      height="100vh"
      alignItems="center"
      ref={section}
      id={CONTACT_US_ID}
      position="relative"
      overflow="hidden"
    >
      <Box position="absolute" width="100%" height="100%" left={0} zIndex={0} dir={"rtl"}>
        <Box
          background={`linear-gradient(to right, ${whiteBg}, rgba(0, 0, 0, 0))`}
          position="absolute"
          width="100%"
          height="100%"
          left={0}
          zIndex={1}
        />

        <Text
          opacity={0.05}
          zIndex={0}
          color="secondaryFontColor"
          fontSize={100}
          className="typings"
        ></Text>
      </Box>

      <Container maxWidth="container.lg" zIndex={10} position="relative">
        <SimpleGrid columns={{ base: 1, lg: 2 }} className="items" gap={{ base: 14, lg: 2 }}>
          <SectionHeading
            as="h3"
            className="heading"
            textAlign={{ base: "center", lg: "right" }}
          ></SectionHeading>

          <Flex alignItems="center" justifyContent="center">
            <ContactUsButton t={t("contact_us_button")} />
          </Flex>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};
