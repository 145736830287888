import React, { useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { log } from "blitz";
import { Flex, Link, Text } from "@chakra-ui/react";

interface Props {}

const languageToLabel = {
  en: "english",
  cs: "czech",
};

export const LanguageSwitch: React.FC<Props> = () => {
  const { i18n, t } = useTranslation("web");
  const { language: currentLanguage } = i18n;
  const router = useRouter();
  const locales = router.locales ?? [currentLanguage];
  const { t: tCore } = useTranslation("core");

  const switchToLocale = useCallback(
    (locale: string) => {
      const path = router.asPath;

      return router.push(path, path, { locale });
    },
    [router],
  );

  console.log("currentLanguage", currentLanguage);

  const switchToLanguage = currentLanguage === "en" ? "cs" : "en";

  return (
    <Flex alignItems="center">
      <Text color="primaryFontColor" textTransform="capitalize">
        <Link>
          <button onClick={() => switchToLocale(switchToLanguage)}>
            {tCore(languageToLabel[switchToLanguage])}
          </button>
        </Link>
      </Text>
    </Flex>
  );
};
