import { useTranslation } from "next-i18next";
import { Routes } from "@blitzjs/next";
import { Button } from "@chakra-ui/react";
import React from "react";
import { Key } from "iconoir-react";

import { InternalLink } from "../../../core/components/Link/InternalLink";

export function AppButton({ buttonProps }: { buttonProps?: React.ComponentProps<typeof Button> }) {
  const { t } = useTranslation("web");

  return (
    <InternalLink href={Routes.TestAccess()}>
      <Button variant="primary" leftIcon={<Key strokeWidth={2.5} />} {...buttonProps}>
        <strong>{t("app_button")}</strong>
      </Button>
    </InternalLink>
  );
}
