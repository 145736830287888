import React from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Mail, Phone } from "iconoir-react";
import { Contact, IContact } from "./Contact";

interface Props {}

const FOOTER_CONTACTS: IContact[] = [
  {
    textKey: "footer_phone_text",
    hrefKey: "footer_phone_href",
    icon: Phone,
  },
  {
    textKey: "footer_email_text",
    hrefKey: "footer_email_href",
    icon: Mail,
  },
];

export const Contacts: React.FC<Props> = () => {
  const { t } = useTranslation("web");

  return (
    <Box>
      <Flex flexDirection="column" gap={2}>
        <Text>{t("footer_contacts_name")}</Text>
        <Text>{t("footer_contacts_adress")}</Text>
        <Spacer height={10} />
        {FOOTER_CONTACTS.map((contact, index) => (
          <Contact key={index} {...contact} />
        ))}
      </Flex>
    </Box>
  );
};
