import { Button, Link } from "@chakra-ui/react";
import { Mail } from "iconoir-react";
import React from "react";

export function ContactUsButton(props: { t: string }) {
  return (
    <Link href="mailto:aprety@aprety.com">
      <Button variant="primary" className="button" leftIcon={<Mail />}>
        {props.t}
      </Button>
    </Link>
  );
}
