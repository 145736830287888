import { useTranslation } from "next-i18next";
import { As, Box, Icon, Link } from "@chakra-ui/react";
import React from "react";

export interface IContact {
  textKey: string;
  hrefKey: string;
  icon: As;
}

export const Contact = (props: IContact) => {
  const { textKey, hrefKey, icon } = props;
  const { t } = useTranslation("web");

  return (
    <Box>
      <Link href={t(hrefKey)}>
        <Icon as={icon} color="white" mr={3} />
        {t(textKey)}
      </Link>
    </Box>
  );
};
