import React, { useRef } from "react";
import {
  As,
  Box,
  Container,
  DarkMode,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  useToken,
} from "@chakra-ui/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Instagram, Linkedin } from "iconoir-react";
import { useTranslation } from "next-i18next";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import { DARK_BG, WHITE_BG } from "../../../core/theme/foundations/colors";
import { Contacts } from "./Contacts";

interface Props {}

interface FooterIconProps {
  icon: As;
  href: string;
  index: number;
}

const FOOTER_ICON_CLASS = "footer-icon";

export const FooterIcon = (props: FooterIconProps) => {
  const { icon, href, index } = props;
  const { t } = useTranslation("web");

  return (
    <Link
      href={t(href)}
      width={20}
      height={20}
      backgroundColor={WHITE_BG}
      borderRadius="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={`${FOOTER_ICON_CLASS}-${index}`}
      transition=".2s all"
      _hover={{ backgroundColor: "primary", "& svg path": { stroke: "white" } }}
    >
      <Icon as={icon} width={8} height={8} color={DARK_BG} transition=".2s all" />
    </Link>
  );
};

const ICONS = [
  { icon: Instagram, href: "instagram_link" },
  { icon: Linkedin, href: "linkedin_link" },
];

export const Footer: React.FC<Props> = () => {
  const { t } = useTranslation("web");
  const section = useRef(null);
  const primaryColor = useToken("colors", "currentColor");

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      for (let i = 0; i < ICONS.length; i++) {
        const selector = `.${FOOTER_ICON_CLASS}-${i}`;
        /*        gsap.to(selector, { backgroundColor: primaryColor, duration: 0.3 });
        gsap.to(selector, { backgroundColor: WHITE_BG, duration: 0.3, delay: 4 });*/
      }
    },
    { scope: section },
  );

  return (
    <Flex backgroundColor={DARK_BG} minHeight="30vh" py={70} ref={section}>
      <DarkMode>
        <Container maxWidth="container.xl">
          <SimpleGrid minChildWidth="200px">
            <Flex gap={6} flexDirection="column">
              <Heading fontSize={20}>{t("footer_contacts_heading")}</Heading>
              <Contacts />
            </Flex>
            <Flex gap={6} flexDirection="column">
              <Heading fontSize={20}>{t("follow_us")}</Heading>
              <Flex gap={10}>
                {ICONS.map((icon, index) => (
                  <FooterIcon key={icon.href} icon={icon.icon} href={icon.href} index={index} />
                ))}
              </Flex>
            </Flex>
          </SimpleGrid>
        </Container>
      </DarkMode>
    </Flex>
  );
};
