import { MISSION_ID } from "../Mission/Mission";
import { PEOPLE_ID } from "../People/People";
import { CONTACT_US_ID } from "../ContactUs/ContactUs";

interface NavItem {
  href: string;
  label: string;
}

const getAnchorLink = (label: string) => `#${label.toLowerCase()}`;

export type NavItems = NavItem[];
export const navItems = [
  { href: getAnchorLink(MISSION_ID), label: "navbar_mission" },
  { href: getAnchorLink(PEOPLE_ID), label: "navbar_roles" },
  { href: getAnchorLink(CONTACT_US_ID), label: "navbar_contact" },
];
